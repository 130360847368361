// CustomPopover.js
import React, { useState, useRef } from 'react';
import './Modal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare } from '@fortawesome/free-solid-svg-icons'
import { Typeahead } from 'react-bootstrap-typeahead';

const CustomPopover = (props) => {
  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState(props.toUser || '');


  const togglePopover = () => {
    setVisible(!visible);
  };
  const subscriberChangeHandler = (result) => {
    if(result.length === 0){
      setInputValue("");
      return;
    }
    setInputValue(result[0].user)
    props.toChangeHandler(result[0].user);
  }

  const sendMessage = () => {
    setVisible(false);
    props.sendMessage();
  }
  const subscribersList = Array.isArray(props.subscribersList) ? props.subscribersList : [];
 

  return (
    <div className="popover-container">
      {/* Trigger button for the popover */}

      <FontAwesomeIcon icon={faShare} onClick={togglePopover} className="custom-data-table-icon" />

      {/* Popover content */}
      {visible && (
        <div className="popover-content">
          <div className="popover-arrow" />
          <div className="popover-header">
            <Typeahead
              id="basic-typeahead"
              className="search-domains"
              labelKey="display_name"
              multiple={false}
              options={subscribersList}
              onChange={subscriberChangeHandler}
              placeholder="Select User..."
            />
            <button className="popover-send" onClick={sendMessage}>
              ➔
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomPopover;